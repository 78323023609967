<script setup>
import { Link, useForm } from "@inertiajs/vue3"
import FormLine from "@/components/FormLine.vue"
import FormField from "@/components/FormField.vue"

defineProps({
  canResetPassword: Boolean,
  status: String,
  errors: Object,
})

const action = route("admin.login.store")
const forgot = route("admin.reset-password.forgot-password")

const form = useForm({
  email: "",
  password: "",
  remember: false,
})
</script>
<template>
  <MinimalLayout title="Log In" heading="Sign In">
    <form @submit.prevent="form.post(action)">
      <div v-if="form.errors.email" class="mb-4">
        <InlineMessage severity="error">{{ form.errors.email }}</InlineMessage>
      </div>

      <FormLine>
        <FormField>
          <InputGroup>
            <InputGroupAddon><i class="pi pi-envelope"></i></InputGroupAddon>
            <InputText id="email" type="email" v-model="form.email" class="w-full" placeholder="Email" />
          </InputGroup>
        </FormField>
      </FormLine>
      <FormLine>
        <FormField>
          <InputGroup>
            <InputGroupAddon><i class="pi pi-lock"></i></InputGroupAddon>
            <InputText id="password" type="password" v-model="form.password" class="w-full" placeholder="Password" />
          </InputGroup>
        </FormField>
      </FormLine>
      <FormLine>
        <FormField>
          <div class="flex align-items-center mb-4">
            <Checkbox v-model="form.remember" inputId="remember" name="remember" :binary="true" />
            <label for="remember" class="ml-2">Remember Me</label>
          </div>
        </FormField>
      </FormLine>

      <Button type="submit" label="Log in" class="p-ripple w-full mb-4" :disabled="form.processing" />

      <Link :href="forgot" class="text-sm"> Forgot your password? </Link>
    </form>
  </MinimalLayout>
</template>
